<template>
  <div class='JZContainer'>
    <el-form class="content_block" label-width='100px' inline  :model='JZForm' :rules='JZFormRules' >
      <el-row>
        <el-col :span="12">
          <el-form-item label="公告类型：" prop="type">
            <el-select :disabled="readonly" v-model="JZForm.type" placeholder='请选择公告类型'>
              <el-option label="系统维护" value="SYSTEM_MAINTENANCE"></el-option>
              <el-option label="行业资讯" value="INDUSTRY_INFORMATION"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="公告标题：" prop="title">
            <el-input  :disabled="readonly"  v-model.trim="JZForm.title" placeholder="请输入公告标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="发布时间：" prop="releaseTime">
             <el-date-picker  type="date" :disabled="readonly"  placeholder="请选择发布时间" value-format="yyyy-MM-dd"  v-model.trim="JZForm.releaseTime"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="公告内容：" prop="content">
            <WangEditor :readonly="true" :content="JZForm.content" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <BottomPanel>
      <el-button type="default" @click="$router.go(-1)">返回</el-button>
    </BottomPanel>
  </div>
</template>

<script>
import {dateFormat} from '@/utils/dateFormat';
import WangEditor from '@/components/common/editor/index.vue';
import { detailAnnouncement} from '@/api/userCenter.js';
export default {
  name: '',
  components: {
    WangEditor,
  },
  methods: {
    getDetailAdInfo () {
      detailAnnouncement({id: this.$route.query.id}).then(res => {
        let currentInfo = res.body;
        this.JZForm = {
          content: currentInfo.content,
          title: currentInfo.title,
          type: currentInfo.type,
          releaseTime: dateFormat(currentInfo.releaseTime).format('YYYY-MM-DD'),
        };
      });
    },
  },
  created () {
    if (this.$route.query.id) {
      this.getDetailAdInfo();
    }
  },
  data () {
    return {
      JZForm: {
        content: '',
        releaseTime: '',
        title: '',
        type: '',
      },
      readonly: true,
      JZFormRules: {
        type: [
          {
            required: true,
            message: '请选择公告类型',
            trigger: ['blur', 'change'],
          },
        ],
        title: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: ['blur', 'change'],
          },
        ],
        releaseTime: [
          {
            required: true,
            message: '请选择发布时间',
            trigger: ['blur', 'change'],
          },
        ],
        content: [
          {
            required: true,
            message: '请输入公告内容',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/.el-form-item--small.el-form-item {
  display: flex;
}
/deep/ .el-form-item--small .el-form-item__content{
  flex: 1;
}
/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
  width:100%;
}
</style>
