<template>
    <!-- 富文本组件 -->
    <div ref="editor" class="wangeditorContainer"></div>
</template>
<script>
import E from 'wangeditor';
export default {
  name: 'editor',
  props: {
    getFullText: {
      // 回调函数callBack
      type: Function,
      default: () => { },
    },
    content: {
      // 回显数据
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
    },
  }, // 回调方法
  data () {
    return {};
  },
  watch: {
    content (val) {
      if (val) {
        this.editor.txt.html(val);
        if (this.readonly) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      }
    },
  },
  mounted () {
    this.editor = new E(this.$refs.editor);
    this.editor.config.height = 200;
    this.editor.config.onchange = html => {
      this.getFullText(html); // 内容赋值
    };
    this.editor.config.showLinkImg = true;
    this.editor.config.uploadImgServer
            = this.editor.config.uploadFileName = 'file';
    this.editor.config.zIndex = 8;
    this.editor.config.uploadImgParams = {
      from: 'editor',
    };
    // 如果默认传递content值则渲染默认内容
    if (this.content) {
      this.editor.txt.html(this.content || '');
    }
    this.editor.create();

    if (this.readonly) {
      this.editor.disable();
    }
  },
};
</script>
<style scoped lang="scss">
  .w-e-toolbar{
    flex-wrap:wrap;
  }
  .w-e-text p {
    width: 100%;
    word-wrap:break-word;
  }
  .wangeditorContainer{
    display: block;
    width: calc(100vw - 440px);
  }
</style>
